<template>
<div id="page-ajuste-inventario">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Ajustes de Inventario" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModalAjuste(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>
                    <template slot="fecha" slot-scope="props">
                        {{ parse_date_time(props.row.fecha) }}
                    </template>

                </v-client-table>
                <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL AJUSTE -->
  <v-dialog v-model="modalAjuste" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Ajuste de Inventario</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalAjuste = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formajuste" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-autocomplete v-model="modelAjuste.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true" item-text="nombre" item-value="_id" clearable
                    @change="consultar()"
                    label="Sucursal" v-if="(sb_sucursal=='0' || sb_sucursal=='')">
                </v-autocomplete>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-autocomplete v-model="modelAjuste.id_articulo" :items="articulos" :hide-no-data="true"
                    :loading="isLoading"
                    :search-input.sync="search"
                    placeholder="Ingrese nombre de artículo para buscar"
                    prepend-icon="search"
                    :hide-selected="true" item-text="nombre" item-value="_id" @change="consultar()"
                    label="Artículo" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="3" lg="3">
                    <v-text-field label="Actual" v-model="modelAjuste.actual" readonly ></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field label="Cantidad" v-model="modelAjuste.cantidad" :rules="[rules.required, validaCantidadFloat, validaCantidadDisminuir]" ></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-autocomplete v-model="modelAjuste.tipo" :items="tipos_ajuste" :hide-no-data="true"
                        :hide-selected="true" item-text="nombre" item-value="nombre"
                        label="Tipo Ajuste" :rules="[rules.required]">
                    </v-autocomplete>
                </v-col>
                <v-col sm="12" md="1" lg="1">
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="2" lg="2"></v-col>
                <v-col sm="12" md="8" lg="8">
                    <v-textarea outlined label="Comentarios" rows="4" v-model="modelAjuste.comentarios" :rules="[rules.required]" ></v-textarea>
                </v-col>
                <v-col sm="12" md="2" lg="2">
                </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalAjuste = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="ajustar()">
          <v-icon>done</v-icon> Ajustar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
    search (val) {

        //Si no ha escrito 4 letras o mas
        if (val == null || val.length<4 ) return

        // Si todavía esta buscando
        if (this.isLoading) return

        this.isLoading = true

        let data = {
                    "selector": {
                        "type":"articulos",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus":"Activo"
                    },
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.articulos = response.data.docs;
                    }else
                        this.articulos = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });

                }).then(()=>{ this.isLoading=false; });
      },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.ajustes')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
      this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
      if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
          this.$router.go();            
      }
      this.modelAjuste.id_sucursal = this.$local_storage.get("sb_sucursal","");
      this.sb_sucursal = this.$local_storage.get("sb_sucursal","");
      this.modelAjuste.nombre_sucursal = this.$local_storage.get("sb_nombre_sucursal","");
      
      this.modelAjuste.usuario = this.$local_storage.get("sb_usuario");
      this.getSucursales();

  },
  data() {
    return {
        sucursal_activa:"",
        isLoading:false,
        sb_sucursal:"",
        model_filters: "",
        props_paginacion: {
            infiniteHandler: this.infiniteHandler,
            total_registros: 0
        },
        fab: [],
      tipos_ajuste: [
        {"nombre":"Aumentar"},
        {"nombre":"Disminuir"},
        ],
        modalAjuste:false,
        menu1:false,
        menu2:false,
        name: "datagrid",
        columns: [
            "nombre_articulo",
            "fecha","usuario","tipo","cantidad","comentarios"
        ],
        options: {
            filterable: ["nombre_articulo",
            "fecha","usuario","tipo","cantidad","comentarios"],
            headings: {
                "nombre_articulo":"Artículo",
            },

        },
        breadcrumbs: [{
            text: "Inicio",
            disabled: false,
            to: "/index"
            },
            {
                text: "Inventario",
                href: "",
                disabled: true,
            },
            {
                text: "Ajustes de Inventario",
                href: ""
            }
        ],

        update: false,

        modelAjuste:{
            type:"lotes_ajustes",
            id_sucursal:"",
            nombre_sucursal:"",
            id_articulo:"",
            nombre_articulo:"",
            codigo_articulo:"",
            cantidad:"",
            precio_compra:"",
            tipo:"Aumentar",
            actual:"",
            comentarios:"",
            usuario:"",
            fecha:""
        },
        rules: {
            required: v => !!v || "Este campo es requerido",
        },
        search: "",
        registros: {
            selected: [],
            items: []
        },
        sucursales:[],
        articulos:[],

    };
  },
  methods: {
      infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      var where = {};
      if(this.sb_sucursal!=null && this.sb_sucursal!="0" && this.sb_sucursal!="")
        where = {"type":"lotes_ajustes","id_sucursal":this.sb_sucursal};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{"type": "desc"},{"fecha": "desc"},{"id_sucursal": "desc"}],
            "use_index":"_design/77fdbc06ccf4b301f4417170555612977fd164b5"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    validaCantidadDisminuir:function(val){
      if(val!=undefined && val!=null && val!=""){
        if(this.validaCantidadFloat(val) && this.modelAjuste.tipo == "Disminuir"){
          try {
            if( parseFloat(val) > parseFloat(this.modelAjuste.actual)){
              return "La cantidad es mayor al actual";
            }
          } catch (error) {
            return true;
          }

        }

      }
      return true;
    },
    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm:ss");
    },

    getNombreArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.nombre;
        else
            return ""
    },
    getNombreSucursal: function(id_sucursal){
        var filter = this.sucursales.find(e => e._id==id_sucursal);
        if(filter)
            return filter.nombre;
        else
            return ""
    },

    getCodigoArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.codigo_barras;
        else
            return ""
    },

    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida";
      } else if(parseFloat(value)>100000){
        return "Cantidad muy grande. Favor de revisar";
      }else
        return true;

    },
    getSucursales: function () {
            let data = {
                  "selector": {
                    "type":"sucursales",
                    "estatus": {
                      "$eq": "Activo"
                    }
                  },
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0)
                        this.sucursales = response.data.docs;
                    else
                        this.sucursales = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });

                });
        },
    openModalAjuste: function(type) {
      if (type === 1) {
        this.update = false;

        this.modelAjuste.id_articulo="";
        this.modelAjuste.nombre_articulo="";
        this.modelAjuste.codigo_articulo="";
        this.modelAjuste.cantidad="";
        this.modelAjuste.precio_compra="";
        this.modelAjuste.actual="";
        this.modelAjuste.comentarios="";
        this.modelAjuste.tipo="Aumentar";
      } else {
        this.update = true;
      }
      this.modalAjuste = true;
    },


    ajustar: function() {

      if (this.$refs.formajuste.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
            type: this.modelAjuste.type,
            id_sucursal: this.modelAjuste.id_sucursal,
            nombre_sucursal: this.getNombreSucursal(this.modelAjuste.id_sucursal),
            id_articulo: this.modelAjuste.id_articulo,
            nombre_articulo: this.getNombreArticulo(this.modelAjuste.id_articulo),
            codigo_articulo: this.getCodigoArticulo(this.modelAjuste.id_articulo),
            cantidad: parseFloat(this.modelAjuste.cantidad),
            precio_compra: parseFloat(this.modelAjuste.precio_compra),
            tipo: this.modelAjuste.tipo,
            comentarios: this.modelAjuste.comentarios,
            usuario: this.modelAjuste.usuario,
            fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
            lotes: [],
        };

        let dataAfecta = {
          id_sucursal: this.modelAjuste.id_sucursal,  //Requerido
          id_articulo: this.modelAjuste.id_articulo,  //Requerido
          cantidad: this.modelAjuste.tipo=="Aumentar"? parseFloat(this.modelAjuste.cantidad) : parseFloat(this.modelAjuste.cantidad)*-1, //Requerido
          es_super: 1, //Requerido  - En este caso super siempre es 1 ya que el ajuste es directo
          fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
          precio_compra: this.modelAjuste.precio_compra,//Solo si no existiera entrada del artículo en el inentario, se pide precio para generar una nueva entrada en lotes
          origen: "ajuste de inventario",
          usuario: this.$local_storage.get("sb_usuario"),
        };

        window.funciones_lotes.ajusteInventarioDirecto(dataAfecta).then(result =>{
            data.lotes = result;
            //console.log(result); //result trae array de lotes {"lote":id_lote, "cantidad":cantidad}

            window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
            .then(response => {
                window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
                });
                this.modalAjuste = false;
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro.",
                    footer: ""
                });
                console.log(error);
            }).then(() => {
                window.dialogLoader.hide();
            });

        }).catch(err => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro.",
                    footer: err
                });
                window.dialogLoader.hide();
        });

      }
    },

    getPreciocompra: function(){
      var self = this;
      var articulo = this.articulos.filter(function(e){
        return e._id == self.modelAjuste.id_articulo;
      });
      if(articulo.length>0){
        return articulo[0].precio_compra_con_iva;
      }
    },

    consultar: function(){
        if(this.modelAjuste.id_sucursal != null && this.modelAjuste.id_sucursal != "" && this.modelAjuste.id_sucursal != "0"
            && this.modelAjuste.id_articulo != null && this.modelAjuste.id_articulo != "") {

        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
            id_articulo: this.modelAjuste.id_articulo,  //Requerido
            id_sucursal: this.modelAjuste.id_sucursal,  //Requerido
        };

        window.funciones_lotes.consultaExistencia(data).then(result =>{
            if(result.length>0){
                this.modelAjuste.actual = parseFloat(result[0].existencia.toFixed(2));
            } else {
                this.modelAjuste.actual = 0;
            }

        }).catch(err => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar existencia de artículo.",
                footer: err
            });
        }).then(() => {
            this.modelAjuste.precio_compra = this.getPreciocompra();
            window.dialogLoader.hide();
          });
      } else {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "No ha seleccionado sucursal o artículo. Favor de verificar",
                footer: ""
            });
      }
    },


  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}
</style>
